<template>
   
        <div class="card-container" @click="openModuleDetail()">
            <div class="image-container">
                <img :src="image ? image : require('../../assets/default-learninig-module-image.png')" class="module-image" />
            </div>
            <div class="title-card">
                <p class="module-number">Module {{ moduleNumber }}</p>
                <h3 class="module-title">{{ title }}</h3>
            </div>


            <div class="description">
                <p>{{ description }}</p>
            </div>
        </div>
</template>

<script>
import router from "../../router/index";

export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        moduleNumber: {
            type: Number,
            required: true,
        },
        image: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            default: "Module Title",
        },
        description: {
            type: String,
            default: "Description goes here.",
        },
    },
    methods:{
        openModuleDetail() {
        router.push(`/learning-modules/${this.id}`);
    },
    }
};
</script>

<style scoped>
.card-container {
    cursor: pointer;
    text-align: center;
    position: relative;
    margin: 20px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.image-container {
    position: relative;
    z-index: 1;
    margin-bottom: -10px;
}

.module-image {
    /* width: 100px; */
    max-height: 300px;
    object-fit: cover;
    /* border-radius: 50%; */
    /* background-color: #e0e0e0; */
    display: block;
    margin: 0 auto;
}
.placeholder {
    width: 100px;
    height: 100px;
    background-color: #e0e0e0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #888;
    margin: 0 auto;
}

.title-card {
    background-color: #4285f4;
    border-radius: 10px;
    padding: 15px 20px;
    z-index: 0;
    position: relative;
    width: 100%;
}

.module-number {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin: 0 0 10px 0;
}

.module-title {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    margin: 0;
}

.description {
    margin-top: 20px;
    font-size: 14px;
    color: #6a6a6a;
}
</style>