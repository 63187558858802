<template>
  <div class="row mt-4 goal-wrapper">
    <p class="col-lg-12 text-purple font-weight-bold">Additional Room Goals:</p>
    <div class="goal-list col-lg-12">
      <div
        class="goal-item mb-1 w-100 row"
        v-for="(goal, index) in listItems"
        :key="goal.id"
      >
        <label :for="'roomGoal' + (index + 1)" class="col-md-6 col-lg-6">
          <span>Room Goal {{ index + 1 }}:</span>
          <span v-if="showText" :class="{
            'text-danger': listItems[index].scorePostValue !== 'n/a' && Number(listItems[index].scorePostValue) <= 4,
            'text-warning': listItems[index].scorePostValue !== 'n/a' && Number(listItems[index].scorePostValue) > 4 && Number(listItems[index].scorePostValue) <= 7,
            'text-success': listItems[index].scorePostValue !== 'n/a' && Number(listItems[index].scorePostValue) > 7
          }">({{ listItems[index].scorePostValue }})</span></label>
        <div class="control-wrapper col-sm-12 col-lg-6">
          <div class="row w-100">
            <div class="col-sm-8">
              <input
                :id="'roomGoal' + (index + 1)"
                :disabled="disabled"
                v-model="listItems[index].value"
                class="form-control"
                type="text"
                @input="getStringGoals(index)"
              />
            </div>
            <!-- <div class="col-md-4"> -->
            <select
              class="form-control col-md-3"
              v-model="listItems[index].scoreValue"
              :disabled="disabled || !listItems[index].value.length"
              @change="getStringScore"
            >
              <option value="n/a">N/A</option>
              <option value="0">0</option>
              <option value="5">5</option>
              <option value="10">10</option>
            </select>
            <!-- </div> -->
          </div>
        </div>
        <button
          @click="deleteCurrentGoal(index)"
          v-if="goalsList.length > 1"
          class="del-button ml-2 btn purple-btn btn-shadow font-weight-bold"
        >
          <FontAwesomeIcon icon="minus" />
        </button>
      </div>
      <div class="manage-goals">
        <button
          v-if="goalsList.length <= 2"
          @click="addGoal"
          class="btn purple-btn btn-shadow mb-2 font-weight-bold"
        >
          <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Add Goal
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    showText: {
      type: Boolean,
      default: false,
    },
    scoreGoals: {
      type: Array,
    },
    isCustomRoom: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    listGoals: {
      default: [
        {
          id: 1,
          value: "",
          scoreValue: "n/a",
        },
      ],
    },
  },
  data() {
    return {
      goals: "",
      goalsList: [
        {
          id: 1,
          value: "",
          scoreValue: "n/a",
        },
      ],
      goalsScore: null,
    };
  },
  watch: {
    goals() {
      this.$emit("goals", this.goals);
    },
    goalsScore() {
      this.$emit("score", this.goalsScore);
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.listGoals.length && this.scoreGoals[0].propertyValue) {
        this.goalsList = this.listGoals;
        const elementsArrayGoal = this.listGoals.split(",");
        const elementsArrayScore =
          this.scoreGoals[0].propertyValue === null ||
          this.scoreGoals[0].propertyValue === "n/a"
            ? "n/a"
            : this.scoreGoals[0].propertyValue.includes(",")
            ? this.scoreGoals[0].propertyValue.split(",")
            : this.scoreGoals[0].propertyValue;
        const resultArray = elementsArrayGoal.map((element, index) => {
          return {
            id: index + 1,
            value: element.slice(1, -1),
            scorePostValue:
              elementsArrayScore === null || elementsArrayScore === "n/a"
                ? "n/a"
                : this.scoreGoals[0].propertyValue?.includes(",")
                ? elementsArrayScore[index]
                  ? elementsArrayScore[index].slice(1, -1)
                  : "n/a"
                : elementsArrayScore.slice(1, -1),
            scoreValue: !this.showText
              ? elementsArrayScore === null || elementsArrayScore === "n/a"
                ? "n/a"
                : this.scoreGoals[0].propertyValue?.includes(",")
                ? elementsArrayScore[index]
                  ? elementsArrayScore[index].slice(1, -1)
                  : "n/a"
                : elementsArrayScore.slice(1, -1)
              : "/",
          };
        });

        this.goalsList = resultArray;
      }
    }, 300);
  },
  computed: {
    listItems() {
      return this.goalsList;
    },
  },
  methods: {
    getStringScore() {
      const createValuesBrackets = this.goalsList.map((obj) =>
        obj.scoreValue !== "" ? `{${obj.scoreValue}}` : ""
      );
      const nonEmptyValues = createValuesBrackets.filter(
        (value) => value !== ""
      );
      const resultString = nonEmptyValues.join(",");
      this.goalsScore = resultString;
    },
    getStringGoals(index) {
      const createValuesBrackets = this.goalsList.map((obj) =>
        obj.value !== "" ? `{${obj.value}}` : ""
      );
      const nonEmptyValues = createValuesBrackets.filter(
        (value) => value !== ""
      );
      const resultString = nonEmptyValues.join(",");
      if (
        index !== null &&
        this.goalsList.length > 1 &&
        this.goalsList[index].value === ""
      ) {
        this.goalsList[index].scoreValue = "n/a";
        this.getStringScore();
      }
      this.goals = resultString;
    },
    addGoal() {
      const lastGoal = this.goalsList[this.goalsList.length - 1];
      const newGoal = {
        id: lastGoal.id + 1,
        value: "",
        scoreValue: "n/a",
      };
      this.goalsList.push(newGoal);
    },
    deleteCurrentGoal(goalIndex) {
      this.goalsList.splice(goalIndex, 1);
      this.getStringGoals(null);
      this.getStringScore();
    },
  },
};
</script>

<style>
.goal-wrapper {
  flex-direction: column;
}

.goal-item {
  display: flex;
  align-items: center;
  position: relative;
}

.control-wrapper {
  display: flex;
  align-items: center;
}

.del-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
