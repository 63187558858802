<template>
  <div>
    <SecondNavBar />
    <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="false" 
                
              ></loading>
               </div>
    <h1 class="purple-title text-center" style="font-size:2rem;">Modules</h1>
  <div class="col-md-2"></div>

    <div class="row">
      <div class="col-md-6 offset-md-3">
        
        <p class="text-center mb-4">{{moduleDescription}}</p>
      </div>
    </div>

    <div class="row cards-area">
      <div v-for="(module, index) in modulesData" :key="index" class="col-md-4 d-flex justify-content-center mb-4 ">
        <LearningModuleCard :id="module.id" :moduleNumber="module.module" :image="module.image" :title="module.title"
          :description="module.subtitle" />
      </div>
    </div>

  </div>
</template>

<script>
import SecondNavBar from '../../components/Clients/SecondNavBar'
import LearningModuleCard from '../../components/LearningModules/LearningModuleCard.vue';
import { contentService } from '../../_services/content.service'
import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading,
    SecondNavBar,
    LearningModuleCard

  },
  data() {
    return {
      isLoading: false,

      modulesData: [],
      moduleDescription:""
    }
  },

  mounted() {
    this.isLoading = true;

    contentService.getLearningModules()
      .then((response) => {
        this.modulesData = response.modules;
        this.moduleDescription = response.moduleDescription;
      }).finally(() => {
        this.isLoading = false;

      })

  }
}
</script>

<style>
.cards-area {
  padding: 0 10%;
  display: flex;
  justify-content: center;
}
</style>