<template>
  <div>
    <div class="row">
      <div
        style="padding-top:10px; max-width:95% !important;"
        class="card shadow col-md-4 mb-4 ml-2 hover-card"
        v-for="room in rooms"
        :key="room.id"
      >
        <router-link
          :to="{
            name: 'EditRoom',
            path: `/clients/${client.id}/rooms/${room.id}`,
            params: {
              roomNameProp: room.roomType,
              roomProp: room,
              roomId: room.id,
              clientProp: client,
            },
          }"
        >
          <div>
            <h2 style="color:black;text-align:center;">{{ room.roomType }}</h2>
            <p style="color:black; text-align:center;font-size:20px;">
              {{ room.location }}
            </p>
            <div class="row">
              <div class="col-md-12">
                <p style="float:right; color:black; font-size:1rem;" class="">
                  <u>PSR&nbsp;</u>
                </p>
                <p style="float:right; color:black; font-size:1rem;" class="">
                  <u>AR</u>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p style="float:left;font-size:0.9rem;color:black;">Initial</p>
                <p
                  style="float:right;color:black;font-weight:bold;font-size:0.85rem;"
                  class=""
                >
                  {{
                    room.personalSafetyPreScore ||
                    room.personalSafetyPreScore == 0
                      ? Math.round(room.personalSafetyPreScore * 10) + "%"
                      : "----"
                  }}
                </p>
                <p
                  style="float:right;color:black;font-weight:bold;font-size:0.85rem;"
                  class=""
                >
                  {{
                    room.accessibilityPreScore ||
                    room.accessibilityPreScore == 0
                      ? Math.round(room.accessibilityPreScore.toFixed(0)) + "%"
                      : "----"
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p style="float:left;font-size:0.9rem;color:black;">
                  Follow-Up
                </p>
                <p
                  style="float:right;color:black;font-weight:bold;font-size:0.85rem;"
                  class=""
                >
                  {{
                    room.personalSafetyPostScore ||
                    room.personalSafetyPostScore == 0
                      ? Math.round(room.personalSafetyPostScore * 10) + "%"
                      : "----"
                  }}
                </p>
                <p
                  style="float:right;color:black;font-weight:bold;font-size:0.85rem;"
                >
                  {{
                    room.accessibilityPostScore ||
                    room.accessibilityPostScore == 0
                      ? Math.round(room.accessibilityPostScore.toFixed(0)) + "%"
                      : "----"
                  }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rooms: Array,
    client: Object,
  },
  methods: {
    getStatus(status) {
      return status;
    },
  },
};
</script>

<style>
.hover-card:hover {
  opacity: 0.5;
  cursor: pointer;
}
</style>
