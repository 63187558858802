<template>
  <div>
    <div v-if="editorProp">
      <button
        class="purple-btn"
        @click="
          editorProp
            .chain()
            .focus()
            .toggleBold()
            .run()
        "
        :class="{ 'is-active': editorProp.isActive('bold') }"
      >
        bold
      </button>
      <button
        class="purple-btn"
        @click="
          editorProp
            .chain()
            .focus()
            .toggleItalic()
            .run()
        "
        :class="{ 'is-active': editorProp.isActive('italic') }"
      >
        italic
      </button>
      <button
        class="purple-btn"
        @click="
          editorProp
            .chain()
            .focus()
            .toggleStrike()
            .run()
        "
        :class="{ 'is-active': editorProp.isActive('strike') }"
      >
        strike
      </button>
      <button
        class="purple-btn"
        @click="
          editorProp
            .chain()
            .focus()
            .toggleBulletList()
            .run()
        "
        :class="{ 'is-active': editorProp.isActive('bulletList') }"
      >
        bullet list
      </button>
      <button class="purple-btn" @click="toggleLink">
        link
      </button>
      <button
        class="purple-btn"
        @click="toggleRecognition"
        :class="{ 'is-active': isListening }"
      >
        {{ isListening ? 'Stop Voice Input' : 'Start Voice Input' }}
      </button>
      <!-- <button @click="editor.chain().focus().undo().run()">
        undo
      </button>
      <button @click="editor.chain().focus().redo().run()">
        redo
      </button> -->
    </div>
    <editor-content
      style=""
      :editor="editorProp"
      :id="numberProp === 1 ? 'rich-editor' : 'rich-editor2'"
    />
  </div>
</template>

<script>
import { EditorContent } from '@tiptap/vue-2';
//import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent,
  },
  props: {
    contentProp: String,
    numberProp: Number,
    editorProp: Object,
  },
  methods: {
    toggleLink() {
      let url = window.prompt('URL');
      if (!url.includes('http')) {
        url = 'https://' + url;
      }
      if (url) {
        this.editorProp
          .chain()
          .focus()
          .setLink({ href: url })
          .run();
      }
    },
    toggleRecognition() {
      if (this.isListening) {
        this.isListening = false;
        this.recognition.stop();
      } else {
        this.isListening = true;
        this.recognition.start();
      }
    },
  },
  data() {
    return {
      editor: this.editorProp,
      isListening: false,
      recognition: null,
    };
  },

  // mounted() {
  //   this.editor = new Editor({
  //     extensions: [
  //       StarterKit,
  //     ],
  //     content: this.contentProp,
  //   })
  // },
  mounted() {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.error('Speech Recognition API is not supported in this browser.');
      return;
    }
    this.recognition = new SpeechRecognition();
    this.recognition.interimResults = false;
    this.recognition.maxAlternatives = 1;
    this.recognition.lang = 'en-US';
    this.recognition.continuous = true;

    this.recognition.onresult = (event) => {
      let transcript = '';
      transcript = event.results[event.results.length - 1][0].transcript;
      transcript = transcript
        .replace(/ period|period$/i, '.')
        .replace(/ comma|comma$/i, ',')
        .replace(/ exclamation mark|exclamation mark$/i, '!')
        .replace(/ question mark|question mark$/i, '?')
        .replace(/new line|new line$/i, '\n');

      const currentContent = this.editorProp.getText();
      const words = currentContent.trim().split(/\s+/);
      const lastWord = words[words.length - 1];
      if (
        /[.!?-]$/.test(lastWord) || // Removed unnecessary escape characters
        /\n\n\s*$/.test(currentContent) ||
        !lastWord
      ) {
        let trimmedResult = transcript.trim();
        transcript =
          ' ' + trimmedResult.charAt(0).toUpperCase() + trimmedResult.slice(1);
      }
      transcript = transcript.replace(
        / hyphen|add hyphen|add hyphen|add -|add -$/i,
        '\n-'
      );
      transcript = transcript.replace(/new paragraph|new paragraph$/i, '\n\n');

      transcript = transcript.replace(
        /(?:\n\n\s+|[.!?-]\s+)([a-z])/g,
        (match, char) => match.replace(char, char.toUpperCase())
      );

      this.editorProp
        .chain()
        .focus()
        .insertContent(transcript)
        .run();
    };

    this.recognition.onend = () => {
      if (this.isListening) {
        this.recognition.start();
        this.isListening = true;

        return;
      }
    };
  },
  beforeDestroy() {
    if (this.editor) {
      this.editorProp.destroy();
    }
  },
};
</script>

<style>
.ProseMirror {
  height: 300px;
  border: 1px solid #6f42c1;
  overflow-y: auto;
}
ul,
ol {
  padding: 0 1rem;
  margin-left: 20px;
}
p {
  margin-left: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  margin-left: 20px;
}

code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

pre {
  background: #0d0d0d;
  color: #fff;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

img {
  max-width: 100%;
  height: auto;
}

blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#0d0d0d, 0.1);
}

hr {
  border: none;
  border-top: 2px solid rgba(#0d0d0d, 0.1);
  margin: 2rem 0;
}
</style>
