<template>
  <div>
      <SecondNavBar/>
      <!-- <h1 class="purple-title text-center">Videos</h1>
      <p>Coming Soon</p> -->

<h1 class="purple-title text-center" style="font-size:2rem;">Frequently Asked Questions</h1>
<div class="row mt-4 mb-4">
<div class="col-md-6 offset-md-3">
<div class="accordion" id="faqAccordion">
  <div class="card" v-for="(faq, index) in faqs" :key="faq.question">
    <div class="card-header" :id="'header' +index">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" :data-target="'#collapse'+ index" aria-expanded="false" :aria-controls="'collapse'+ index">
          {{faq.question}}
        </button>
      </h2>
    </div>

    <div :id="'collapse'+ index" class="collapse" :aria-labelledby="'header' + index" data-parent="#faqAccordion">
      <div class="card-body" v-html="faq.answer">
      </div>
    </div>
  </div>


</div>
</div>
</div>

</div>
</template>

<script>
import SecondNavBar from '../../components/Clients/SecondNavBar'
import {contentService} from '../../_services/content.service'
export default {
    components:{
        SecondNavBar
    },
    data(){
      return{
        faqs: [{question: '', answer: ''}]
      }
    },

    mounted(){
      contentService.getFaqs()
      .then((response) => {
        this.faqs = response;
        console.log(this.faqs);
      })

    }
}
</script>

<style>

</style>