<template>
  <div class="row">
    <nav
      class="col-12 col-md-12 shadow navbar navbar-expand-lg navbar-light"
      id="main-nav-bar"
    >
      <div class="col-6 col-md-3 col-lg-4">
        <a class="navbar-brand" id="brand-nav-bar"
          ><router-link to="/"
            ><img
              src="../../assets/logo.png"
              class="img-fluid"
              alt="home for life logo"/></router-link
        ></a>
      </div>
      <div class="col-2 col-md-1 col-lg-3 offset-3 offset-md-8 offset-lg-0">
        <button
          @click="toggleNav()"
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-md-auto">
          <router-link to="/clients">
            <li class="nav-item">
              <button class="nav-link btn border-btn">Clients</button>
            </li>
          </router-link>
          <router-link to="/admin-dashboard">
            <li class="nav-item" v-if="role == 'Admin'">
              <button class="nav-link btn border-btn">Admin Dashboard</button>
            </li>
          </router-link>
          <li class="nav-item">
            <button
              class="nav-link btn border-btn"
              @click="navigateToContactUs"
            >
              Contact
            </button>
          </li>
          <li class="nav-item">
            <div class="dropdown nav-item">
              <button
                style="width:200px;"
                class="nav-link btn border-btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="dropdownMenu"
              >
                <FontAwesomeIcon icon="user" />&nbsp;Profile
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li class="nav-item">
                  <router-link to="/profile"
                    ><a class="dropdown-item">My Account</a></router-link
                  >
                </li>
                <li v-if="role == 'Manager'">
                  <router-link to="/my-organization"
                    ><a class="dropdown-item">My Organization</a></router-link
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="dropdown-item"
                    @click="downloadDocument(userGuideName)"
                    target="_blank"
                    >How To Guide</a
                  >
                </li>
                <li v-if="role == 'Manager'" class="nav-item">
                  <a
                    class="dropdown-item"
                    @click="downloadDocument(managerGuideName)"
                    target="_blank"
                    >Manager How To Guide</a
                  >
                </li>
                <li class="nav-item" v-if="role == 'Manager'">
                  <a
                    class="dropdown-item"
                    @click="downloadDocument(professorGuideName)"
                    target="_blank"
                    >Professor's Guide</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="dropdown-item"
                    @click="downloadDocument(voiceToTextGuideName)"
                    href="#"
                    >Voice to Text Prompts</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="dropdown-item"
                    @click="downloadDocument(scoringGuideName)"
                    target="_blank"
                    >Scoring Decision Guide</a
                  >
                </li>
                <li class="nav-item">
                  <router-link to="/faq"
                    ><a class="dropdown-item"
                      >FAQ's</a
                    ></router-link
                  >
                </li>
                <li class="nav-item">
                  <a href="" @click="logout()" class="dropdown-item">Logout</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { userService } from "../../_services/index";
import axios from 'axios';
import { config } from '../../../config';

export default {
  data() {
    return {
      toggled: false,
      role: "User",
      userGuideName: "UserGuide.pdf",
      managerGuideName: "ManagerGuide.pdf",
      professorGuideName: "ProfessorGuide.pdf",
      scoringGuideName: "ScoringGuide.pdf",
      voiceToTextGuideName: "VoiceToTextGuide.pdf"
    };
  },
  components: {
    FontAwesomeIcon,
  },
  methods: {
    toggleNav: function() {
      this.toggled = !this.toggled;
    },
    navigateToContactUs: function() {
      window.location.href =
        "https://www.homeforlifedesign.com/professional-home-assessment";
    },
    logout() {
      userService.logout();
      this.$store.commit("setIsLoggedIn", false);
    },
    async downloadDocument(documentName) {
      try {
        // Assuming you have stored the JWT token in localStorage
        const token = localStorage.getItem('token');
        const apiEndpoint = config.apiUrl + "/query/documents?fileName=" + documentName;

        const response = await axios.get(apiEndpoint, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', documentName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    }
  },
  mounted() {
    userService.getIfManager().then((response) => {
      this.role = response.data;
      localStorage.setItem("Role", response.data);
    });
  },
};
</script>

<style>
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
#main-nav-bar {
  margin-bottom: 50px;
}

/* Full Screen Nav */
@media only screen and (min-width: 1000px) {
  /* #landing-right-link{
    margin-right:310px;
    border-right: 1px dashed lightgrey;
  } */
  /* #brand-nav-bar{
      margin-left:200px;
  } */
  .border-btn {
    border-left: 1px dashed lightgrey !important;
  }
  .nav-link {
    color: darkslategray !important;
  }
  .nav-link:hover {
    background-color: #6b6b6b;
    color: white !important;
    font-weight: bold !important;
    border: none !important;
  }
}

@media only screen and (max-width: 400px) {
  .navbar-toggler {
    float: right;
  }
}
@media only screen and (max-width: 800px) {
  .nav-item {
    background-color: #6b6b6b !important;
    margin-bottom: 2px;
  }
  .nav-link {
    color: white !important;
    margin-left: 5px;
  }
}
</style>