<template>
    <div>
        <SecondNavBar />

        <div v-if="isLoading" class="text-center">
            <div class="vld-parent">
                <loading :active.sync="isLoading" :can-cancel="false"></loading>
            </div>
        </div>
        <div v-else class="main-module-body">
            <div class="header-section">

                <div class="module-header">
                    <div class="module-image-container">
                        <img :src="module.image ? module.image : require('../../assets/default-learninig-module-image.png')"
                            alt="Module Image" class="module-image" />
                    </div>
                    <div class="module-title-card">

                        <div class="module-title-text">
                            <p class="module-number">Module {{ module.module }}</p>
                            <h3 class="module-title">{{ module.title }}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-box">
                <h4 class="content-title">Overview:</h4>
                <p>{{ module.overview }}</p>
            </div>

            <div class="content-box">
                <h4 class="content-title">Objectives:</h4>
                <p>{{ module.objective }}</p>
            </div>

            <div class="content-box">
                <h4 class="content-title">Directions:</h4>
                <p v-html="module.direction"></p>
            </div>

            <div class="content-box">
                <h4 class="content-title">Videos:</h4>
                <div class="video-grid">
                    <div class="  video-item" v-for="(video, index) in module.videos" :key="index" >
                        <iframe :src="video.url" frameborder="0"
                            allow=" clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                            <p class="purple-title" style="font-size:19px;">{{ video.title }}</p>
                            <p>{{ video.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { contentService } from '../../_services/content.service'
import Loading from "vue-loading-overlay";
import SecondNavBar from '../../components/Clients/SecondNavBar'


export default {
    components: {
        Loading,
        SecondNavBar,

    },
    data() {
        return {
            isLoading: true,
            module: {
                module: 0,
                title: '',
                image: '',
                overview: '',
                objective: '',
                direction: '',
                videos: [],
            },
        };
    },
    mounted() {
        contentService.getLearningModuleById(this.$route.params.id).then(
            (response) => {
                if (response == -1) return this.$router.push('/learning-modules');
                this.module = response;
                this.isLoading = false;
            },
            () => {
                this.isLoading = false;
                this.$router.push('/learning-modules');
            }
        );
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
};
</script>

<style scoped>
.main-module-body {
    padding: 0 10%;
}

.purple-title {
    color: #5c4b82;
    font-weight: bold;
    margin-top: 10px;
}

.header-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.module-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

}



.module-image-container img {
    max-width: 100px;
    max-height: 300px;
    /* border-radius: 50%; */
    overflow: visible;
    left: 100px;
    top: 0;
    bottom: 0;
    position: relative;
}

.module-title-card {

    background-color: #4a90e2;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    width: fit-content;
    text-align: center;
    display: flex;
    align-items: center;
    padding-left: 100px;

}

.module-number {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.module-title {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
}

.content-box {
    border: 1px solid #ccc;
    padding: 15px;
    margin: 20px 0;
    border-radius: 8px;
}

.content-title {
    color: #5c4b82;
    font-weight: bold;
}

.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.video-item iframe {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    border: 1px solid #ccc;
}
</style>