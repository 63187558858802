<template>
  <div>
    <button :disabled="preventEdit" @click="onPickFile">Upload Pictures</button>
    <input
      type="file"
      style="display:none;"
      ref="fileInput"
      accept="image/*"
      multiple
      @change="onFilesPicked"
    />
    <Modal v-model="showModal" title="Delete Image">
      <button class="btn purple-btn">Back</button>
      <button
        @click="deletePicture()"
        style="float:right;"
        class="btn btn-danger"
      >
        Delete
      </button>
    </Modal>
    <p v-if="isLoading">Loading Room Images...</p>
    <p v-if="hasFailed">Failed to load room images.</p>
    <h3 class="mt-4 text-purple" v-if="preImages.length > 0">
      Initial Assessment Photos
    </h3>
    <div class="row" v-for="image in preImages" :key="image.id">
      <span
        @click="showDeleteModal(image.id)"
        class="text-danger font-weight-bold col-md-1"
        style="cursor:pointer;"
        >X</span
      >
      <a :href="image.fileName" target="_blank">
        <img
          class="img-fluid col-md-3 mb-2"
          :src="image.fileName"
          :alt="image.fileName"
        />
      </a>
    </div>
    <h3 class="mt-4 text-purple" v-if="postImages.length > 0">
      Follow-Up Assessment Photos
    </h3>
    <div class="row" v-for="image in postImages" :key="image.id">
      <span
        @click="showDeleteModal(image.id)"
        class="text-danger font-weight-bold col-md-1"
        style="cursor:pointer;"
        >X</span
      >
      <a :href="image.fileName" target="_blank">
        <img
          class="img-fluid col-md-3 mb-2"
          :src="image.fileName"
          :alt="image.fileName"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { roomService } from '../../_services/index';
import VueModal from '@kouts/vue-modal';
import heic2any from 'heic2any';
import '@kouts/vue-modal/dist/vue-modal.css';
export default {
  props: {
    roomId: null,
    clientId: null,
    preventEdit: Boolean,
  },
  components: {
    Modal: VueModal,
  },
  data() {
    return {
      showModal: false,
      images: [],
      preImages: [],
      postImages: [],
      isLoading: true,
      hasFailed: false,
      deletePictureId: null,
      indexToDelete: null,
      selectedFiles: [],
    };
  },
  mounted() {
    this.isLoading = true;
    roomService
      .getRoomImages(this.$route.params.id, this.$route.params.roomId)
      .then(
        (response) => {
          this.isLoading = false;
          this.images = response.data;
          this.preImages = response.data.filter(
            (photo) => photo.roomPhotoTypeId === 1
          );
          this.postImages = response.data.filter(
            (photo) => photo.roomPhotoTypeId === 2
          );
          this.hasFailed = false;
        },
        () => {
          this.isLoading = false;
          this.hasFailed = true;
        }
      );
  },
  methods: {
    showDeleteModal(imageId) {
      this.showModal = true;
      this.deletePictureId = imageId;
    },
    deletePicture() {
      this.showModal = false;
      this.$emit('toggle-loading', true);
      for (var i = 0; i < this.images.length; i++) {
        if (this.images[i].id == this.deletePictureId) {
          this.indexToDelete = i;
          break;
        }
      }
      roomService
        .deleteRoomImage(
          this.$route.params.id,
          this.$route.params.roomId,
          this.deletePictureId
        )
        .then(
          () => {
            this.images.splice(this.indexToDelete, 1);
            this.preImages = this.images.filter(
              (photo) => photo.roomPhotoTypeId === 1
            );
            this.postImages = this.images.filter(
              (photo) => photo.roomPhotoTypeId === 2
            );
            this.$emit('toggle-loading', false);
            this.$toast.success('Image Deleted Successfully!', {
              position: 'bottom-center',
              timeout: 5000,
              hideProgressBar: true,
            });
          },
          () => {
            this.$emit('toggle-loading', false);
            this.$toast.error('Failed to delete image.', {
              position: 'bottom-center',
              timeout: 5000,
              hideProgressBar: true,
            });
          }
        );
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },

    async onFilesPicked(event) {
      const files = event.target.files;

      this.$emit('toggle-loading', true);

      try {
        await this.uploadImagesSequentially(files, 0);
        this.$toast.success('Images Uploaded Successfully!', {
          position: 'bottom-center',
          timeout: 5000,
          hideProgressBar: true,
        });
      } catch (error) {
        console.error(error);
        this.$toast.error('Failed to upload images.', {
          position: 'bottom-center',
          timeout: 5000,
          hideProgressBar: true,
        });
      } finally {
        this.$refs.fileInput.value = '';
        this.$emit('toggle-loading', false);
      }
    },

    async uploadImagesSequentially(files, index) {
      if (index < files.length) {
        const targetFile = files[index];

        try {
          const processedFile = await this.processFile(targetFile);
          const response = await this.savePictures(processedFile, index);
          this.images.push(response);

          if (response.roomPhotoTypeId === 2) {
            this.postImages.push(response);
          } else {
            this.preImages.push(response);
          }

          await this.uploadImagesSequentially(files, index + 1);
        } catch (error) {
          console.error(error);
          throw new Error('Failed to upload images sequentially.');
        }
      }
    },

    processFile(targetFile) {
      return new Promise((resolve, reject) => {
        if (targetFile.name.includes('.heic')) {
          heic2any({
            blob: targetFile,
            toType: 'image/jpeg',
            quality: 0.94,
          })
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              console.error(err);
              reject(new Error('Failed to process .heic file.'));
            });
        } else {
          resolve(targetFile);
        }
      });
    },

    savePictures(image) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', image, image.name);

        if (
          image.type !== 'image/png' &&
          image.type !== 'image/jpg' &&
          image.type !== 'image/jpeg' &&
          image.type !== 'image/gif' &&
          image.type !== 'image/heic'
        ) {
          this.$toast.error(
            'Error. File must be JPEG/JPG, PNG, GIF, or HEIC.',
            {
              position: 'bottom-center',
              timeout: 5000,
              hideProgressBar: true,
            }
          );
          reject(new Error('Invalid file type'));
          return;
        }

        const clientId = this.clientId;
        const roomId = this.roomId;

        roomService
          .saveImages(clientId, roomId, formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },
  },
};
</script>

<style></style>
